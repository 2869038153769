import React from "react";
import "./Privacy.css";

const Privacy = () => {
  return (
      <div className="privacy">
        <h1>FindFlames Safety and Child Protection Policy</h1>

        <h2>1. Introduction</h2>
        <p>
          At FindFlames, the safety and well-being of our users are paramount. We are committed to fostering a safe,
          respectful, and inclusive environment for all users, particularly children and vulnerable individuals. This
          policy outlines the measures we take to prevent child sexual abuse and exploitation (CSAE), ensure user
          safety, and address violations of our guidelines.
        </p>

        <h2>2. Our Commitment to Safety</h2>
        <p>FindFlames is committed to:</p>
        <ul>
          <li>Prohibiting behavior that exploits or endangers minors.</li>
          <li>Promoting responsible and respectful interactions on our platform.</li>
          <li>Taking immediate action to address harmful activities, including CSAE.</li>
        </ul>

        <h2>3. Reporting and Enforcement Mechanisms</h2>
        <p>Users can report inappropriate behavior or content through:</p>
        <ul>
          <li>The in-app "Report" feature.</li>
          <li>Contacting our support team at <a href="mailto:support@findflames.com">support@findflames.com</a>.</li>
        </ul>
        <p>Upon receiving a report, we:</p>
        <ul>
          <li>Investigate promptly.</li>
          <li>Suspend or ban users found to violate our guidelines.</li>
          <li>Cooperate with law enforcement as required by Indian law.</li>
        </ul>

        <h2>4. User Education</h2>
        <p>We provide users with resources to help them navigate FindFlames safely, including:</p>
        <ul>
          <li>Avoiding sharing sensitive personal information.</li>
          <li>Recognizing and reporting unsafe behaviors.</li>
          <li>Blocking and reporting abusive users.</li>
        </ul>
        <p>Visit our <a href="#">Safety Tips</a> page for more information.</p>

        <h2>5. Content Moderation</h2>
        <p>Our platform employs automated tools and manual reviews to monitor and remove harmful content, such as:</p>
        <ul>
          <li>Explicit images or videos of minors.</li>
          <li>Harassment, hate speech, or bullying.</li>
          <li>Violations of Indian child protection laws.</li>
        </ul>

        <h2>6. Collaboration and Advisory</h2>
        <p>
          FindFlames collaborates with local and global organizations specializing in child safety and online security
          to ensure our policies and tools are up to date and effective.
        </p>

        <h2>7. Compliance with Indian Laws</h2>
        <p>
          Our safety measures comply with Indian laws and international standards for child safety and data protection.
          FindFlames is committed to supporting legal authorities in addressing online harm.
        </p>

        <h2>8. Data Protection and Privacy</h2>
        <p>
          All user data is handled per our <a href="https://findflames.com/privacy" target="_blank"
                                              rel="noopener noreferrer">Privacy Policy</a>,
          ensuring it is stored and managed securely. Sensitive data, especially of minors, is treated with the utmost
          care to prevent unauthorized access or misuse.
        </p>

        <h2>9. Contact Us</h2>
        <p>
          If you have questions or concerns about this policy, please contact us at <a
            href="mailto:support@findflames.com">support@findflames.com</a>.
        </p>
      </div>

  );
};

export default Privacy;
